import React, { useState } from "react";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

import clientConfig from "../../client-config";
import { imageUrlFor } from "../lib/image-url";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj,
  getBlogUrl,
  cn
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(limit: 6) {
      edges {
        node {
          publishedAt
          id
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    featured: allSanityFeatured {
      nodes {
        content {
          ... on SanityPost {
            id
            publishedAt
            mainImage {
              ...SanityImage
              alt
            }
            title
            _rawExcerpt
            slug {
              current
            }
            excerpt {
              style
              list
            }
          }
          ... on SanityExternalContent {
            id
            publishedAt
            mainImage {
              ...SanityImage
              alt
            }
            title
            externalName
            url
            _rawExcerpt
            excerpt {
              style
              list
            }
          }
        }
      }
    }
  }
`;

const FeatureItem = ({
  title,
  slug,
  publishedAt,
  mainImage,
  _rawExcerpt,
  externalName,
  ...props
}) => {
  let url = props.url;
  let description = _rawExcerpt[0].children[0].text;
  const fluidProps = getFluidGatsbyImage(
    mainImage.asset._id,
    { maxWidth: 675 },
    clientConfig.sanity
  );
  fluidProps.aspectRatio = 1 / 0.606060606;

  let ContentLink = props => <a href={url} target="_blank" {...props} />;
  if (url == null || url.length === 0) {
    ContentLink = props => <Link to={getBlogUrl(publishedAt, slug.current)} {...props} />;
  }

  return (
    <div className=" pb-8 last:pb-0 flex flex-col sm:flex-row  lg:flex-col feature">
      <div className="relative mb-2 sm:mb-0 lg:mb-2 bg-cover w-full sm:w-6/12 lg:w-full feature-img">
        <ContentLink className="block ">
          <Img
            fluid={fluidProps}
            alt={mainImage.alt}
            className="mb-1"
            caption={mainImage.caption}
          />
        </ContentLink>
      </div>
      <div className="w-full sm:w-6/12 sm:pl-2 lg:pl-0 lg:w-full feature-body">
        <ContentLink className="block ">
          <h2 className="mb-2 text-lg sm:text-lg md:text-xl font-regular leading-snug font-display">
            {title}
          </h2>
        </ContentLink>
        <h5 className="mb-2 text-xs leading-snug tracking-wider font-display">
          {format(publishedAt, "MMM Do, YYYY")}
          {externalName ? ` - Published in ${externalName}` : ""}
        </h5>
        <p className="text-sm max-w-sm leading-normal tracking-wide text-lowWhite ">
          {description}
        </p>
      </div>
    </div>
  );
};
const FeaturedList = ({ items, className, ...props }) => {
  console.log(">>>", items);
  return (
    <div
      className={cn(
        "w-11/12 xs:w-9/12 sm:w-full md:w-10/12 lg:w-7/12 2xl:w-9/12 lg:pr-2",
        className
      )}
    >
      {items.map(item => {
        return <FeatureItem key={"Feature-" + item.id} {...item} />;
      })}
    </div>
  );
};
const Newsletter = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [isSuccess, setSuccess] = useState(null);
  const onSubmit = data => {
    addToMailchimp(data.email, { FNAME: data.name })
      .then(data => {
        if (data.result === "success") {
          setSuccess(data.msg);
        }
      })
      .catch(() => {
        console.log("Mailchimp never throws errors. Panic");
      });
  };

  return (
    <div className="relative w-full px-4 sm:px-0 mb-9 pt-6 md:pt-9 border-t-1 border-white">
      {isSuccess != null && (
        <div className="absolute text-center top-0 bg-darkBlue px-4 left-0 right-0 bottom-0 flex items-center justify-center">
          <div className=" max-w-2xl mx-auto">
            Thanks for subscribing! Please check your inbox and confirm your subscription!
            <br /> I just need to know you are real.
          </div>
        </div>
      )}
      <div className="flex justify-center flex-col md:flex-row">
        <div className="w-full mx-auto md:mx-0 sm:w-10/12 md:w-7/12 lg:w-5/12 pb-4 md:pb-0 sm:pr-1">
          <h3 className="font-display text-2lg xs:text-xl mb-3 sm:text-2xl lg:text-3xl font-regular leading-tight tracking-normal">
            Me make article or project.
            <br />
            You get email or read it?
          </h3>
          <p className="text-sm leading-relaxed tracking-wide text-lowWhite">
            A newsletter about learning Web-creative! <br />
            I'll share anything interesting I read or write. WebGl, canvas, creative-coding etc
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full mx-auto sm:w-10/12 md:mx-0 md:w-4/12 lg:w-3/12 md:pl-1 flex flex-col items-end md:-mt-5"
        >
          <label htmlFor="name-input" className="w-full mb-1 pl-1 font-display">
            Name
          </label>
          <input
            type="text"
            id="name-input"
            className="mb-4 py-2 px-4 w-full border-1 border-white bg-transparent rounded-soft font-display"
            name="name"
            placeholder="Jose"
            ref={register}
          />
          <p htmlFor="email-input" className="w-full mb-1 pl-1 font-display">
            Email
          </p>
          <input
            type="text"
            id="email-input"
            className="mb-4 py-2 px-4 w-full border-1 border-white bg-transparent rounded-soft font-display"
            name="email"
            placeholder="jose@gmail.com"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address"
              }
            })}
          />
          <input
            type="submit"
            className="e-bump cursor-pointer font-display w-full sm:w-auto rounded-soft flex-grow-0 border-1 border-white hover:bg-transparent hover:text-white py-2 px-6 bg-white text-black "
            value="Subscribe"
          />
        </form>
      </div>
    </div>
  );
};
const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  let featured = data.featured.nodes.map(item => item.content);

  let leftFeatured = featured.slice(0, Math.floor(featured.length / 2));
  let rightFeatured = featured.slice(Math.floor(featured.length / 2));

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        <div className="w-full flex flex-col lg:flex-row pb-9 pt-8 sm:pt-9">
          <div className="w-full lg:w-1/2 flex lg:pr-1 col flex-col items-center lg:items-start ">
            <div className="w-full flex justify-start lg:justify-end pb-9 pl-0">
              <div className="sm:pl-4 md:pl-0 md:w-full lg:w-auto text-left md:text-center lg:text-left">
                <h1 className=" font-display text-3xl xs:text-4xl  sm:text-5xl font-light mb-2 sm:mb-3 leading-tight">
                  Writing creative
                  <br />
                  dev things
                </h1>
                <h3 className=" tracking-widest text-xs sm:text-base uppercase leading-snug text-lowerWhite">
                  Personal Favorites Articles
                </h3>
              </div>
            </div>
            <FeaturedList className="feature-left pb-8 lg:pb-0" items={leftFeatured} />
          </div>
          <div className="w-full lg:w-1/2 flex lg:pl-1 justify-center lg:justify-start">
            <FeaturedList className="feature-right" items={rightFeatured} />
          </div>
        </div>
        <div className="w-full flex justify-center items-center pb-9 ">
          <Link
            to="/blog"
            className="e-bump font-display py-2 px-4 border-1 rounded-soft border-white bg-transparent hover:bg-white hover:text-black "
          >
            Browse all articles
          </Link>
        </div>
        <Newsletter />
      </Container>
    </Layout>
  );
  // {postNodes && (
  //         <BlogPostPreviewList
  //           title="Latest blog posts"
  //           nodes={postNodes}
  //           browseMoreHref="/archive/"
  //         />
  //       )}
};

export default IndexPage;
